.page_bg_item{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.page_bg_item.bg1{
  background-image: url(../../img/lnd30/bg1.jpg);
  //opacity: 1;
}
.page_bg_item.bg2{
  background-image: url(../../img/lnd30/bg2.jpg);
}
.page_bg_item.bg3{
  background-image: url(../../img/lnd30/bg3.jpg);
}
.page_bg_item.bg4{
  background-image: url(../../img/lnd30/bg4.jpg);
}
.page_bg_item.bg5{
  background-image: url(../../img/lnd30/bg5.jpg);
}
.page_bg_item.bg6{
  background-image: url(../../img/lnd30/bg6.jpg);
}
.page_bg_item.bg7{
  background-image: url(../../img/lnd30/bg7.jpg);
}

.page_content{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page_header{
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logo_container{
  max-width: 110px;
}

.page_header_google_btn{
  width: 230px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  cursor: pointer;
  transition: .3s;
}

.page_header_google_btn:hover{
  background-color: #fff;
  color: #333;
}

.page_header_google_btn img{
  margin-right: 12px;
}

.page_copy{
  font-family: "Roboto", sans-serif;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 9px 30px;
  background: rgba(75, 95, 113, 0.6);
  border-radius: 0 6px 0 0;
  font-size: 12px;
  color: #fff;
}

.page_copy span{
  text-decoration: underline;
  cursor: pointer;
}

.page_copy a{
  color: #fff;
}

.page_policy{
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 63px;
  padding: 28px 21px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px 10px 0 0;
}

.page_policy_text{
  color: #000;
}

.page_policy_link{
  width: max-content;
  margin: 10px auto 0;
  line-height: 1;
  text-decoration: underline;
  cursor: pointer;
}

.page_policy_link a {
  color: #E55535;
}

.page_policy_close{
  position: absolute;
  left: calc(100% + 12px);
  top: 0;
  width: max-content;
  cursor: pointer;
}

.block{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page_block_1{
  margin: auto;
  text-align: center;
}

.page_big_title{
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 72px;
  color: #FFFFFF;
}

.page_start_text{
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #FFFFFF;
  max-width: 620px;
  margin: 21px auto 0;
}

.btn{
  font-family: "Roboto", sans-serif;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-weight: bold;
  font-size: 26px;
  color: #fff;
  cursor: pointer;
  max-width: 100%;
}

.fill{
  background-color: #E55535;
  transition: box-shadow .5s;
}

.btn:hover{
  box-shadow: 0 8px 25px rgba(0,0,0,.47);
}

.stroke{
  border: 2px solid #E55535;
}

.page_start_btn{
  width: 361px;
  margin: 39px 0 40px;
}

.page_start_reg_text{
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
}

.page_start_reg_text span{
  text-transform: uppercase;
  font-size: 24px;
}

.page_quest_buttons_row{
  display: flex;
  align-items: center;
  margin-top: 27px;
}

.page_quest_buttons_row > div,
.page_reg_input_row .btn{
  width: 254px;
}

.page_skip_btn{
  margin-left: 15px;
}

.page_quest_title{
  font-family: "Roboto", sans-serif;
  font-size: 45px;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 27px;
}

.page_quest_text{
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  max-width: 546px;
  text-align: center;
}

.text_quest_4{
  font-size: 30px;
}

.page_quest_checks_wrap{
  margin-top: 40px;
  margin-bottom: 8px;
}

.page_quest_checks_row{

}

.page_quest_checks_row + .page_quest_checks_row{
  margin-top: 20px;
}

.page_quest_checks_row input{
  display: none;
}

.page_quest_checks_row label{
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  font-size: 30px;
  color: #FFFFFF;
  cursor: pointer;
}

.page_quest_checks_row label:before{
  content: '';
  display: block;
  width: 33px;
  height: 33px;
  background-color: #fff;
  border: 2px solid #DADDE1;
  border-radius: 3px;
  box-sizing: border-box;
  margin-right: 28px;
  background-position: center;
  background-repeat: no-repeat;
}

.page_quest_checks_row input:checked + label:before{
  border-color: #fff;
  background-image: url(../../img/lnd30/check_blue.svg);
}

.page_reg_text{
  font-family: "Roboto", sans-serif;
  max-width: 680px;
  font-weight: 500;
  font-size: 36px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  text-align: center;
}

.page_reg_input_row{
  display: flex;
  align-items: center;
  margin-top: 31px;
}

.page_reg_box_input{
  position: relative;
}

.page_reg_input_wrap span,
.page_reg_box_input span{
  position: absolute;
  bottom: -25px;
  left: 5px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}

.page_reg_input{
  display: block;
  font-family: "Roboto", sans-serif;
  width: 428px;
  height: 62px;
  border: 2px solid #FFFFFF;
  border-radius: 6px;
  background-color: transparent;
  margin-right: 15px;
  font-weight: bold;
  font-size: 26px;
  color: #FFFFFF;
  text-align: left;
}

.page_reg_input.input_name{
  margin-bottom: 0;
}

.page_reg_input.error{
  border: 2px solid #E55535;
}

.page_reg_input.input_num{
  text-align: center;
}

.page_reg_input:hover,
.page_reg_input:focus{
  border-color: #fff;
}

.page_reg_input::placeholder{
  text-align: left;
  color: #fff;
}

.page_reg_input_wrap{
  position: relative;
  margin-top: 30px;
  margin-bottom: 35px;
  width: 428px;
}

.page_reg_input_wrap:not(.input_name) .page_reg_input:first-child{
  margin-bottom: 19px;
}

.page_find_btn{
  width: 428px;
}

@media screen and (max-width: 1399px){
  .page_bg_item.bg7{
    background-position: left center;
  }

  .page_bg_item.bg2{
    background-position: right center;
  }

  .page_bg_item.bg5{
    background-position: left center;
    background-size: calc(100% + 200px);
  }

  .page_big_title{
    font-size: 55px;
    max-width: 415px;
  }

  .page_start_text{
    font-size: 18px;
    max-width: 415px;
  }

  .page_start_btn{
    margin: 34px 0;
  }

  .page_quest_title{
    font-size: 36px;
  }

  .page_quest_text{
    font-size: 30px;
  }

  .btn{
    height: 55px;
    font-size: 24px;
  }

  .page_quest_buttons_row > div,
  .page_reg_input_row .btn{
    width: 227px;
  }

  .page_quest_checks_row label{
    font-size: 24px;
  }

  .page_quest_checks_row label:before{
    width: 28px;
    height: 28px;
    background-size: 80%;
  }

  .page_reg_text{
    font-size: 30px;
    max-width: 445px;
  }

  .page_reg_input{
    height: 55px;
    font-size: 20px;
  }

  .page_reg_input.input_num{
    width: 300px;
  }
}

@media screen and (max-width: 1299px){
  .page_bg_item.bg5{
    background-position: center;
    background-size: cover;
  }
}

@media screen and (max-width: 1199px){
  .page_quest_text{
    font-size: 24px;
  }

  .page_reg_text{
    font-size: 24px;
  }
}

@media screen and (max-width: 799px){
  .page_bg_item.bg1{
    background-image: url(../../img/lnd30/bg1m.jpg);
  }
  .page_bg_item.bg2{
    background-image: url(../../img/lnd30/bg2m.jpg);
  }
  .page_bg_item.bg3{
    background-image: url(../../img/lnd30/bg3m.jpg);
  }
  .page_bg_item.bg4{
    background-image: url(../../img/lnd30/bg4m.jpg);
  }
  .page_bg_item.bg5{
    background-image: url(../../img/lnd30/bg5m.jpg);
  }
  .page_bg_item.bg6{
    background-image: url(../../img/lnd30/bg6m.jpg);
  }
  .page_bg_item.bg7{
    background-image: url(../../img/lnd30/bg7m.jpg);
  }

  .page_header{
    padding: 20px 30px;
  }

  .page_big_title{
    font-size: 44px;
  }

  .page_start_btn{
    margin: 34px 0;
  }

  .page_quest_title{
    font-size: 36px;
  }

  .btn{
    height: 55px;
    font-size: 24px;
  }

  .page_quest_buttons_row > div,
  .page_reg_input_row .btn{
    width: 227px;
  }

  .page_quest_checks_row label{
    font-size: 24px;
  }

  .page_quest_checks_row label:before{
    width: 28px;
    height: 28px;
    background-size: 80%;
  }

  .page_reg_text{
    font-size: 30px;
    max-width: 570px;
  }

  .page_reg_input{
    height: 55px;
    font-size: 20px;
  }

  .page_header_google_btn{
    width: 200px;
    font-size: 12px;
  }

  .page_header_google_btn img{
    width: 24px;
    margin-right: 10px;
  }

  .page_reg_input.input_num{
    width: 300px;
  }
}

@media screen and (max-width: 599px){
  .block{
    padding: 0 15px;
  }

  .page_block_1{
    padding: 0 15px;
  }

  .page_header{
    padding: 20px;
  }

  .page_start_text{
    font-size: 14px;
    margin-top: 19px;
  }

  .page_big_title{
    font-size: 36px;
  }

  .page_start_btn{
    max-width: 340px;
    width: 100%;
    margin: 34px 0;
  }

  .page_quest_buttons_row{
    margin-top: 18px;
  }

  .page_quest_title{
    font-size: 30px;
    margin-bottom: 18px;
  }

  .page_quest_text{
    font-size: 22px;
  }

  .btn{
    height: 40px;
    font-size: 16px;
  }

  .page_quest_buttons_row > div{
    width: 136px;
  }

  .page_reg_input_row .btn{
    width: 100%;
  }

  .page_quest_checks_wrap{
    margin-top: 18px;
    margin-bottom: 0;
  }

  .page_quest_checks_row label{
    font-size: 18px;
  }

  .page_quest_checks_row label:before{
    width: 26px;
    height: 26px;
    margin-right: 14px;
  }

  .page_reg_text{
    font-size: 22px;
  }

  .page_reg_input{
    width: 100%;
    height: 38px;
    font-size: 16px;
  }

  .page_header_google_btn{
    width: max-content;
    font-size: 12px;
    border: none;
    height: auto;
    flex-direction: row-reverse;
  }

  .page_header_google_btn span span{
    display: none;
  }

  .page_header_google_btn img{
    width: 24px;
    margin-right: 0;
    margin-left: 5px;
  }

  .page_reg_input.input_num{
    height: 38px;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .page_reg_input_row{
    display: block;
  }

  .page_start_reg_text{
    font-size: 12px;
  }

  .page_start_reg_text span{
    font-size: 14px;
  }

  .page_policy{
    width: 244px;
    padding: 15px 20px;
    font-size: 12px;
    right: 50%;
    transform: translateX(50%);
  }

  .page_policy_close{
    left: calc(100% + 5px);
  }

  .page_quest_checks_row + .page_quest_checks_row{
    margin-top: 10px;
  }

  .page_find_btn{
    width: 100%;
  }

  .page_reg_input_wrap{
    width: 100%;
  }

  .page_copy{
    width: 100%;
    padding: 4px 0;
    font-size: 9px;
    text-align: center;
  }
}